<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Modelos </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="success" dark class="mb-2" :to="{name: 'product-model.add'}">Adicionar Novo Modelo</v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadProductModels" :search="search" :rowsPerPageItems="rowsPerPageItems">
            <template v-slot:items="props">
                <td width="15%" v-if="props.item.marca.prod_b_logo"><img :src="`${base_url}upload/product_brand/${props.item.marca.prod_b_logo}`" class="avatar"></td>
                <td width="15%" v-if="!props.item.marca.prod_b_logo"><img :src="`${base_url}upload/logo.png`" class="avatar"></td>
                <td>{{ props.item.prod_mo_nome }}</td>
                <td>{{ props.item.prod_mo_descricao }}</td>
                <td class="text-xs-center">
                    <v-switch v-model="props.item.prod_mo_status" @change="onChangeEventHandlerStatus(props.item.prod_mo_id, $event)" class="my-switch"></v-switch>
                </td>
                <td class="text-xs-center">
                    <v-btn flat icon color="blue lighten-1" @click.prevent="editProductModel(props.item)">
                        <v-icon dark>edit</v-icon>
                    </v-btn>
                    <v-btn flat icon color="red lighten-1" @click.prevent="deleteProductModel(props.item, props.index)">
                        <v-icon dark>delete_outline</v-icon>
                    </v-btn>
                </td>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Voce procurou por "{{ search }}" nenhum item localizado.
                </v-alert>
            </template>
        </v-data-table>
    </v-card>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    name: "ProductModelComponent",
    created() {
        this.$store.dispatch('loadProductModels');
    },
    computed: {
        loadProductModels() {
            if (this.$store.state.productmodels.items.data == null)
                return []

            return this.$store.state.productmodels.items.data;
        }
    },
    data() {
        return {
            search: '',
            rowsPerPageItems: [10,20,30,{"text":"$vuetify.dataIterator.rowsPerPageAll","10":-1}],
            headers: [{
                    text: 'Marca',
                    align: 'left',
                    sortable: false,
                    value: 'marca.prod_b_logo'
                },
                {
                    sortable: false,
                    text: 'Nome',
                    value: 'prod_mo_nome'
                },
                {
                    sortable: false,
                    text: 'Descrição',
                    value: 'prod_mo_descricao'
                },
                {
                    sortable: false,
                    text: 'Status',
                    value: 'prod_mo_status'
                },
                {
                    text: 'Ações',
                    align: 'center',
                    sortable: false,
                    value: 'acoes'
                }
            ],
            productmodel: [],
            base_url: URL_BASE
        }
    },
    methods: {
        editProductModel(item) {
            this.$router.push({
                name: 'product-model.edit',
                params: {
                    prod_mo_id: item.prod_mo_id
                }
            });
        },
        detailProductModel(item) {
            this.$router.push({
                name: 'product-model.detail',
                params: {
                    prod_mo_id: item.prod_mo_id
                }
            });
        },
        deleteProductModel(item, index) {
            this.$swal({
                title: 'Você tem certeza?',
                text: "Você não poderá reverter isso",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#D32F2F',
                cancelButtonColor: '#1976D2',
                confirmButtonText: 'Sim, Exclua!'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('destroyProductModel', item.prod_mo_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: "center",
                                    type: "success",
                                    title: 'Sucesso',
                                    text: 'Dados excluídos com sucesso',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.$store.state.productmodels.items.data.splice(index, 1);
                            } else {
                                this.$swal({
                                    position: "center",
                                    type: "error",
                                    title: 'Opssss!',
                                    text: 'Erro ao excluir dados',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                type: "error",
                                title: 'Opssss!',
                                text: 'Algo errado aconteceu!',
                                showConfirmButton: true,
                                timer: 6000
                            });
                        })
                }
            })
        },
        onChangeEventHandlerStatus(prod_mo_id, value) {
            this.$store.dispatch('updateProductModelStatus', {
                    prod_mo_id: prod_mo_id,
                    prod_mo_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        type: "success",
                        title: 'Sucesso',
                        text: 'Status do Status atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar status do Status',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {

    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
